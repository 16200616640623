import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "me-4" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_7 = {
  class: "badge-light-primary badge me-3",
  style: {"font-size":"13px","padding-top":"14px","padding-bottom":"14px"}
}
const _hoisted_8 = { class: "card-toolbar" }
const _hoisted_9 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_10 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_customers_export_modal"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "card-body pt-0" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "badge-light-info badge" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "svg-icon svg-icon-3" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "svg-icon svg-icon-3" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_PackagesFilter = _resolveComponent("PackagesFilter")!
  const _component_vue3_json_excel = _resolveComponent("vue3-json-excel")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'supplier-packages'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                disabled: _ctx.loading,
                type: "button",
                class: "btn btn-flex btn-light btn-active-primary fw-bolder me-3",
                "data-kt-menu-trigger": "click",
                "data-kt-menu-placement": "bottom-start",
                "data-kt-menu-flip": "top-end"
              }, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
              ], 8, _hoisted_5),
              _createVNode(_component_PackagesFilter, {
                onTypeSelected: _ctx.onTypeChange,
                onReset: _ctx.onReset
              }, null, 8, ["onTypeSelected", "onReset"]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.tableData.length) + " out of " + _toDisplayString(_ctx.totalItems), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_vue3_json_excel, {
                "json-data": _ctx.excelData,
                fields: _ctx.excelHeader,
                worksheet: "shipments WorkSheet",
                name: "packages.xls"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr078.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('Export Packages')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["json-data", "fields"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 100,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-trackNumber": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.trackNumber), 1)
            ]),
            "cell-shipmentTrackNumber": _withCtx(({ row: packages }) => [
              _createElementVNode("a", {
                style: {"cursor":"pointer"},
                class: "text-gray-600 text-hover-primary mb-1",
                onClick: ($event: any) => (_ctx.moveToShipment(packages.originShipmentId))
              }, _toDisplayString(packages.shipmentTrackNumber), 9, _hoisted_13)
            ]),
            "cell-areaName": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.areaName), 1)
            ]),
            "cell-officeName": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.officeName), 1)
            ]),
            "cell-name": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.name), 1)
            ]),
            "cell-driverName": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.driverName), 1)
            ]),
            "cell-passportNumber": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.passportNumber), 1)
            ]),
            "cell-createdAt": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.createdAt), 1)
            ]),
            "cell-status": _withCtx(({ row: packages }) => [
              _createElementVNode("span", _hoisted_14, _toDisplayString(packages.status.internal), 1)
            ]),
            "cell-recipientPhoneNumber": _withCtx(({ row: packages }) => [
              _createTextVNode(_toDisplayString(packages.recipientPhoneNumber), 1)
            ]),
            "cell-actions": _withCtx(({ row: packages }) => [
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                onClick: _withModifiers(($event: any) => (_ctx.moveToShipment(packages.originShipmentId)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_16, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen010.svg" })
                ])
              ], 8, _hoisted_15),
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                onClick: _withModifiers(($event: any) => (_ctx.overView(packages.id)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_18, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
                ])
              ], 8, _hoisted_17),
              (_ctx.can('remove', 'supplier-packages'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                    onClick: _withModifiers(($event: any) => (_ctx.deletePackages(packages)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_20, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                    ])
                  ], 8, _hoisted_19))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}